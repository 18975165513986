<!--
 * @Author: your name
 * @Date: 2021-11-24 16:23:09
 * @LastEditTime: 2023-02-10 15:30:18
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 首页 媒体动态
 * @FilePath: \new-website-1123\src\views\Home\media\index.vue
-->
<template>
  <div class="programme-swiper">
    <div class="home-media">
      <div class="content-div pt80">
        <div class="title-div">
          <h-title :titleimg="titleimg" :stitleimg="stitleimg"></h-title>
        </div>
        <m-nav
          :navList="navList.slice(0, 4)"
          :active="navActive"
          @change="navChange"
        ></m-nav>
      </div>
      <div class="right-div" >
        <img  :src="currentItem.imageUrl" />
      </div>
    </div>
    <div class="home-media-moile">
      <h-title :titleimg="titleimg" :stitleimg="stitleimg"></h-title>
      <swiper
        class="right-divmap swiper"
        ref="swiper"
        :options="swiperOptions"
        @slideChange="slideChange"
      >
        <swiper-slide v-for="item in advantageList.slice(0,4)" :key="item.key">
          <slot :row="item">
            <div class="right-divmap-connect">
              <img
                class="data-img"
                :src="item.imageUrl"
                alt=""
                :style="{
                  'object-fit': 'cover',
                }"
              />
              <div class="views">
                <h2>{{ item.name }}</h2>
                <h4>{{ item.createTime }}</h4>
                <h6 @click="goNewsDetails(item)">查看详情></h6>
              </div>
            </div>

            <div class="filter-div"></div>
          </slot>
        </swiper-slide>
        <div class="home-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { clearHtml } from "@/utils/removeTag.js"

import {getNesListApi} from "@/api/index.js";
import hTitle from "../components/title.vue";
import mNav from "./nav.vue";
export default {
  name: "",
  components: { hTitle, mNav },
  data() {
    let w = window.innerWidth > 800 ? 5 : 1;
    return {
      swiperOptions: {
        pagination: {
          el: ".home-media-moile .home-pagination",
          clickable: true,
        },
        autoplay: true,
        autoHeight: true,
        loop: true,
        speed: 800,
        mousewheel: false,
        slidesPerGroup: 1,
        loopedSlides: w,
        spaceBetween: 20,
      },
      titleimg: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/whitetitle5.png",
      stitleimg: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/whitetitle5s.png",
      advantageList: [ ],
      navList: [],
      navActive: 0,
    };
  },
  created() {this.getNewsList()},
  computed: {
    currentItem() {
      return this.navList[this.navActive] || {};

    },
  },
  methods: {
    navChange(val) {
      this.navActive = val;
    },
    slideChange() {
      this.$emit("change", this.$refs.swiper.$swiper.realIndex);
    },
    getNewsList(){
      getNesListApi({status:1,pageSize:'999',pageNum:'1'}).then(res=>{
        const temp = res.data.data.list
        const result1 = temp.filter(el=> el.type == '行业动态').slice(0,2)
        const result2 = temp.filter(el=> el.type == '上太新闻').slice(0,1)
        const result3 = temp.filter(el=> el.type == '影像风采').slice(0,1)
        const resultAll = [...result1,...result2,...result3]
        console.log(resultAll);
        console.log(resultAll);
        this.navList = resultAll ||[]
        this.advantageList = resultAll || []
      })
    },
    clearHtml(text){
      return clearHtml(text)
    },
    goNewsDetails(item){
      this.$router.push({
        path: "/news/details",
        query: {
          id:  item.id,
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.home-media {
  width: 100%;
  height: 100vh;
  display: flex;
  .content-div {
    padding-left: 11vw;
    width: 49%;
    padding-right: 50px;
    .title-div {
      margin-bottom: 30px;
      padding-top: 30px;
    }
  }
  .right-div {
    width: 51%;
    height: 100%;
    padding-top: 80px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.home-media-moile {
  text-align: center;
  display: none;
  .home-title {
    margin-bottom: 0.91rem;
  }
}
.programme-swiper {
  width: 100%;
  overflow: hidden;

  .desc-p {
    width: 1200px;
    text-align: center;
    color: rgba($color: #fff, $alpha: 0.7);
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    margin: 50px auto;
  }

  .desc-paragraph {
    width: 1400px;
    text-align: center;
    color: rgba($color: #fff, $alpha: 0.7);
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    margin: 50px auto;
  }

  @media screen and (max-width: 1400px) {
    .desc-p {
      width: 1100px;
      font-size: 18px;
    }
    .desc-paragraph {
      width: 1200px;
      font-size: 20px;
    }
  }
}
.home-pagination {
  position: absolute;
  bottom: -30px;
  text-align: center;
  top: auto;
  ::v-deep {
    .swiper-pagination-bullet {
      width: 24px;
      height: 3px;
      border-radius: 0;
      background-color: rgba($color: #fff, $alpha: 0.8);
      &.swiper-pagination-bullet-active {
        background-color: $color-active;
      }
    }
  }
}
.right-divmap {
  width: 100%;
}
.data-img {
  width: 100%;
  height: 100%;
  object-fit: cover;//
}
.filter-div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #161616;
  opacity: 0.4;
  transition: opacity 0.3s;
}

::v-deep {
  .swiper-container .swiper-wrapper .swiper-slide-active {
    width: 600px;
    .filter-div {
      // background-color: rgba($color: #161616, $alpha: 0);
      opacity: 0;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .home-media-moile {
    display: block;
  }

  .home-media {
    display: none;
  }

  .right-divmap {
    width: 86%;
    min-width: 86%;
    .right-divmap-connect {
      width: 100%;
      height: 100%;
      color: #fff;
      img {
        width: 100%;
        height: 5.76rem;
      object-fit: contain;

      }
      .views {
        padding-top: 0.27rem;
        padding-left: 0.69rem;
        height:   100%;
    height: 100%;
        min-height: 100%;
        background: rgba(255, 255, 255, 0.05);
        padding-right: 0.69rem;
        padding-bottom: 0.45rem;
        text-align: center;
        h2 {
          font-size: 0.36rem;
          font-weight: normal;
          line-height: 130%;
          min-height: 0.935rem;
          // 超过两行一...表示
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

        }
        h4 {
          font-size: 0.2rem;
          color: rgba(255, 255, 255, 0.4);
          padding-top: 0.18rem;
          padding-bottom: 0.36rem;
        }
        h6 {
          color: $color-active;
          font-size: 0.25rem;
          z-index: 999;
        }
      }
    }
  }
  .programme-swiper {
    padding: 0;

    width: 100%;
    overflow: unset;
    .desc-p {
      width: 86%;
      font-size: 0.27rem;
      line-height: 0.44rem;
      margin: 0.27rem auto;
    }

    .desc-paragraph {
      width: 86%;
      font-size: 0.27rem;
      line-height: 0.44rem;
      margin: 0.27rem auto;
    }
  }

  ::v-deep {
    .filter-div {
      height: 0;
    }
    .swiper-container {
      overflow: visible !important;
      width: 86vw;
      height: 5.91rem;
    }
    .swiper-container .swiper-wrapper .swiper-slide {
      width: 86vw;
      position: relative;
    }
    .swiper-container .swiper-wrapper .swiper-slide-prev {
      height: auto !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-prev img {
      height: 5.76rem !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-next {
      height: auto !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-next img {
      height: 5.76rem !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-active {
      .filter-div {
        // background-color: rgba($color: #161616, $alpha: 0);
        opacity: 0;
      }
    }
  }
}
</style>
