<!--
 * @Author: your name
 * @Date: 2021-11-24 13:36:14
 * @LastEditTime: 2021-12-24 17:35:26
 * @LastEditors: Please set LastEditors
 * @Description: 关于上太 nav
 * @FilePath: \new-website-1123\src\views\Home\about\nav.vue
-->
<template>
  <ul class="about-nav">
    <li
      v-for="item in navList"
      :key="item.key"
      class="shou"
      :class="{
        'about-active': item.key == navActive,
      }"
      @click="navClick(item)"
    >
      <span class="title-span">
        {{ item.label }}
      </span>
      <span class="desc-span">
        {{ item.desc }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "about-nav",
  data() {
    return {
      navList: [
        {
          key: 1,
          label: "理念",
          desc: "上善惠农·太平兴中",
        },
        {
          key: 2,
          label: "愿景",
          desc: "以科技服务农业，让服务升级体验",
        },
        {
          key: 3,
          label: "使命",
          desc: "成为顶尖的绿色智慧农业综合服务商",
        },
      ],
      navActive: 1,
    };
  },
  created() {},
  methods: {
    navClick(item) {
      this.navActive = item.key;
      this.$emit("change", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.about-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > li {
    display: flex;
    flex-direction: column;
    color: #fff;
    transition: all 0.3s;
    width: 360px;
    &.about-active {
      color: $color-active;
      .title-span {
        &::after {
          width: 80px;
        }
      }
    }
    .title-span {
      position: relative;
      font-size: 36px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 28px;
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        background-color: $color-active;
        transition: width 0.3s;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .desc-span {
      font-size: 20px;
      text-align: center;
      padding: 0 20px;
    }
    &:hover {
      .title-span {
        &::after {
          width: 80px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .about-nav {
    li {
      width: 33.3333%;
      .title-span {
        font-size: 0.33rem;
        margin-bottom: 0.27rem;
      }
      .desc-span {
        font-size: 0.24rem;
        line-height: 0.35rem;
        padding: 0 0.22rem;
      }
    }
  }
}
</style>
