<!--
 * @Author: your name
 * @Date: 2021-11-24 15:22:47
 * @LastEditTime: 2023-02-10 15:34:24
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 解决方案
 * @FilePath: \new-website-1123\src\views\Home\programme\index.vue
-->
<template>
  <div class="programme-swiper">
    <div class="home-programme">
      <div class="programme-content">
        <div class="left-div pt80">
          <div class="title-div">
            <h-title :titleimg="titleimg" :stitleimg="stitleimg"></h-title>
            <!--解决方案-->
          </div>
          <p-nav
            :nav-list="dataList"
            :active="navActive"
            @change="navChange"
          ></p-nav>
        </div>
        <div class="right-div">
          <img class="data-img" :src="currentItem.cover" alt="上太科技" />
          <!-- <img class="chart-img" :src="currentItem.chartCover" alt="上太科技" /> -->
        </div>
      </div>
    </div>

    <div class="home-media-moile">

      <h-title :titleimg="titleimg" :stitleimg="stitleimg" ></h-title>
      <swiper
        class="right-divmap swiper"
        ref="swiper"
        :options="swiperOptions"
        @slideChange="slideChange"
      >
        <swiper-slide v-for="item in advantageList" :key="item.key" >
          <slot :row="item" >
            <div class="right-divmap-connect">
              <img
                class="data-img"
                :src="item.cover"
                alt=""
                :style="{
                  'object-fit': 'cover',
                }"
              />
              <div class="views">
                <h2>{{ item.title }}</h2>
                <h4
                  style="
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    white-space: normal;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    line-height: 0.35rem;
                    height: 0.87rem;
                    overflow: hidden;
                  "
                >
                  {{ item.desc }}
                </h4>
                <h6 class="more" style="cursor: pointer; z-index: 999;" @click="goMore(item)">了解更多 ></h6>
              </div>
            </div>

          </slot>
        </swiper-slide>
        <div class="home-pagination2" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import hTitle from "../components/title.vue";
import pNav from "./nav.vue";
export default {
  name: "home-programme",
  components: {
    hTitle,
    pNav,
  },
  data() {
    let w = window.innerWidth > 800 ? 5 : 1;
    return {
      swiperOptions: {
        pagination: {
          el: ".home-media-moile .home-pagination2",
          clickable: true,
        },
        autoplay: true,
        loop: true,
        speed: 800,
        mousewheel: false,
        autoHeight: true,
        slidesPerGroup: 1,
        loopedSlides: w,
        spaceBetween: 20,


      },
      titleimg: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/whitetitle2.png",
      stitleimg: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/whitetitle2s.png",
      advantageList: [
        {
          key: 1,
          title: "病虫害绿色防控解决方案",
          desc: "上太科技智慧农业病虫害绿色防控解决方案是基于现代智能物联网技术的绿色植保防控综合性解决方案",
          path: "/programme/pests",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/pests/banner.png",
        },
        {
          key: 2,
          title: "水肥一体化解决方案",
          desc: "上太水肥一体化系统根据作物种类及其需水需肥规律，设置周期性水肥灌溉计划",
          path: "/programme/pests",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/house/banner.png",
        },
        {
          key: 3,
          title: "智慧生产管理解决方案",
          desc: "以海量的作物数据库与病虫害数据库为基础，覆盖农业种植的产前、产中、产后三阶段，实现农业种植管理智慧化。",
          path: "/programme/production",
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/banner.png",
        },
        {
          key: 4,
          title: "智慧温室解决方案",
          desc: "上太智慧温室管理系统从多款传感器获得环境参数信息，并进行存储、显示、数据管理、分析图表展示。",
          path: "/programme/greenhouse",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/water/banner.jpg",
        },
      ],
      dataList: [
        {
          id: 1,
          title: "病虫害绿色防控解决方案",
          desc: "上太科技智慧农业病虫害绿色防控解决方案是基于现代智能物联网技术的绿色植保防控综合性解决方案",
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/pests/banner.png",
          chartCover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/img1.png",
          path: "/programme/pests",
        },
        {
          id: 2,
          title: "水肥一体化解决方案",
          desc: "上太水肥一体化系统根据作物种类及其需水需肥规律，设置周期性水肥灌溉计划",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/house/banner.png",
          chartCover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/img1.png",
          path: "/programme/water-fertilizer",
        },
        {
          id: 3,
          title: "智慧生产管理解决方案",
          desc: "以海量的作物数据库与病虫害数据库为基础，覆盖农业种植的产前、产中、产后三阶段，实现农业种植管理智慧化。",
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/banner.png",
          chartCover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/img1.png",
          path: "/programme/production",
        },
        {
          id: 4,
          title: "智慧温室解决方案",
          desc: "上太智慧温室管理系统从多款传感器获得环境参数信息，并进行存储、显示、数据管理、分析图表展示。",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/water/banner.jpg",
          chartCover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/img1.png",
          path: "/programme/greenhouse",
        },
      ],
      navActive: 1,
    };
  },
  created() {},
  computed: {
    currentItem() {
      let arr = this.dataList.filter((item) => item.id == this.navActive);
      return arr[0];
    },
  },
  methods: {
    navChange(val) {
      this.navActive = val;
    },
    slideChange() {
      this.$emit("change", this.$refs.swiper.$swiper.realIndex);
    },
    goMore(data){
      this.$router.push(data.path);
    },
    temp(e,h){
      const h3 = document.querySelector('.more');
      h3.addEventListener('click',()=>{
      })
      console.log(e.target);
    }
  },
};
</script>

<style lang="scss" scoped>
.home-programme {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .programme-content {
    // padding-left: 11%;
    // width: 78%;
    // margin: auto;
    height: 100%;
    display: flex;
    .left-div {
      width: 49%;
      padding-left: 11vw;
      .title-div {
        font-size: 80px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #ffffff;
        padding-top: 30px;
      }
    }
    .right-div {
      width: 51%;
      height: 100%;
      position: relative;
      padding-top: 80px;
      .data-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .chart-img {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 60%;
        height: unset;
      }
    }
  }
}

.home-media-moile {
  text-align: center;
  display: none;
  .home-title {
    margin-bottom: 0.91rem;
  }
}
.programme-swiper {
  width: 100%;
  overflow: hidden;

  .desc-p {
    width: 1200px;
    text-align: center;
    color: rgba($color: #fff, $alpha: 0.7);
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    margin: 50px auto;
  }

  .desc-paragraph {
    width: 1400px;
    text-align: center;
    color: rgba($color: #fff, $alpha: 0.7);
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    margin: 50px auto;
  }

  @media screen and (max-width: 1400px) {
    .desc-p {
      width: 1100px;
      font-size: 18px;
    }
    .desc-paragraph {
      width: 1200px;
      font-size: 20px;
    }
  }
}
.home-pagination2 {
  position: absolute;
  bottom: -30px;
  text-align: center;
  top: auto;
  ::v-deep {
    .swiper-pagination-bullet {
      width: 24px;
      height: 3px;
      border-radius: 0;
      background-color: rgba($color: #fff, $alpha: 0.8);
      &.swiper-pagination-bullet-active {
        background-color: $color-active;
      }
    }
  }
}
.right-divmap {
  width: 100%;
}
.data-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.filter-div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #161616;
  opacity: 0.4;
  transition: opacity 0.3s;
}


@media screen and (max-width: $mobile-width) {
  .home-media-moile {
    display: block;

  }

  .home-programme {
    display: none;
  }

  .right-divmap {
    width: 86%;
    .right-divmap-connect {
      color: #fff;
      img {
        height: 5.76rem;
      }
      .views {
        padding-top: 0.27rem;
        padding-left: 0.69rem;
        background: rgba(255, 255, 255, 0.05);
        padding-right: 0.69rem;
        padding-bottom: 0.45rem;
        text-align: center;
        h2 {
          font-size: 0.36rem;
          font-weight: normal;
          line-height: 130%;
        }
        h4 {
          font-size: 0.2rem;
          color: rgba(255, 255, 255, 0.4);
          padding-top: 0.18rem;
          margin-bottom: 0.36rem;
        }
        h6 {
          color: $color-active;
          font-size: 0.25rem;
        }
      }
    }
  }
  .programme-swiper {
    padding: 0;

    width: 100%;
    overflow: unset;
    .desc-p {
      width: 86%;
      font-size: 0.27rem;
      line-height: 0.44rem;
      margin: 0.27rem auto;
    }

    .desc-paragraph {
      width: 86%;
      font-size: 0.27rem;
      line-height: 0.44rem;
      margin: 0.27rem auto;
    }
  }

  ::v-deep {
    .filter-div{
      height: 0;
      display: none;
    }
    .swiper-container {
      overflow: visible !important;
      width: 86vw;
      height: 5.91rem;
    }
    .swiper-container .swiper-wrapper .swiper-slide {
      position: relative;
    }
    .swiper-container .swiper-wrapper .swiper-slide-prev {
      height: auto !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-prev img {
      height: 5.76rem !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-next {
      height: auto !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-next img {
      height: 5.76rem !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-active {
      .filter-div {
        // background-color: rgba($color: #161616, $alpha: 0);
        opacity: 0;
      }
    }
  }

}
</style>
