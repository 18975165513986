<!--
 * @Author: your name
 * @Date: 2021-11-24 11:16:24
 * @LastEditTime: 2022-04-28 15:23:56
 * @LastEditors: Please set LastEditors
 * @Description: 首页视频
 * @FilePath: \new-website-1123\src\views\Home\video.vue
-->
<template>
  <div class="home-video">
    <video
      class="pc-itme"
      src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/viedo/banner.mp4"
      muted
      autoplay
      loop
    >
      您的浏览器不支持video播放视频
    </video>

    <video
      x5-playsinline
      webkit-playsinline
      playsinline
      controlslist="nodownload"
      poster="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/wepvideo.jpg"
      class="mobile-itme"
      src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/viedo/banner.mp4"
      loop="true"
      muted="false"
      id="video"
    >
      您的浏览器不支持video播放视频
    </video>

    <p class="d-p1">
      <span class="f-s">上善惠农</span>
      <span class="s-s">太平兴中</span>
    </p>
    <p class="d-p">用科技之光点亮智慧农业发展新希望</p>

    <div
      class="iconbofang1 iconfont posti-play"
      id="poster"
      @click="play()"
    ></div>
    <div class="filter-div" @click="down">
      <img class="down-img" src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/down.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      swiper: null
    };
  },
  created() {},
  mounted(){

  },
  methods: {
    play() {
      var video = document.getElementById("video");
      document.getElementById("poster").style.display = "none";
      video.play();
    },
    down(){
    }
  },
};
</script>

<style lang="scss" scoped>
.pc-itme {
  display: block !important;
}
.mobile-itme {
  display: none !important;
}
.home-video {
  width: 100%;
  height: 100vh;
  position: relative;
  .d-p1 {
    position: absolute;
    top: 42%;
    font-size: 46px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    white-space: nowrap;
    span {
      display: block;
      padding-left: 24px;
      &.s-s {
        padding-left: 48px;
      }
      &.f-s {
        position: relative;
        padding-right: 24px;
        &::after {
          content: "";
          position: absolute;
          top: 30%;
          height: 50%;
          right: 0;
          width: 2px;
          background-color: rgba($color: #fff, $alpha: 0.4);
        }
      }
    }
  }
  .d-p {
    position: absolute;
    top: 50%;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 300;
    color: #ffffff;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
  .posti-play {
    display: none;
    position: absolute;
    top: 50%;
    color: #fff;
    left: 50%;
    font-size: 0.67rem;
    margin-left: -0.34rem;
  }
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
  }
  .filter-div {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    top: 0;
    width: 100%;
    max-height: 100%;
    height: 100vh;
    @keyframes downAni {
      from,
      to {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
      }
    }
    .down-img {
      position: absolute;
      left: 50%;
      bottom: 20px;
      width: 22px;
      margin-left: -11px;
      animation: downAni 2s infinite linear;
    }
  }
}

@media only screen and (max-width: 800px) {
  .pc-itme {
    display: none !important;
  }
  .mobile-itme {
    display: block !important;
  }

  .home-video {
    .d-p1 {
      font-size: 0.49rem;
      letter-spacing: 0;
      width: 86.4%;
      top: 36%;
      justify-content: center;
      span {
        padding-left: 0.2rem;
        &.s-s {
          padding-left: 0.2rem;
        }
        &.f-s {
          padding-left: 0;
          padding-right: 0.2rem;
        }
      }
    }
    .d-p {
      font-size: 0.27rem;
      width: 86.4%;
      top: 44%;
      text-align: center;
      white-space: normal;
    }
    .posti-play {
      display: block;
    }
  }
}
</style>
