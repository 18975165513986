<!--
 * @Author: your name
 * @Date: 2021-11-24 15:23:57
 * @LastEditTime: 2023-02-10 15:36:30
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 首页解决方案 nav
 * @FilePath: \new-website-1123\src\views\Home\programme\nav.vue
-->
<template>
  <ul class="programme-nav">
    <li
      v-for="item in navList"
      :key="'programme-' + item.id"
      class="data-li shou"
      :class="{
        'programme-active': item.id == active,
      }"
      @click="$emit('change', item.id)"
    >
      <span class="title-span">
        {{ item.title }}
      </span>
      <p class="desc-p">
        {{ item.desc }}
      </p>
      <div class="more">
        <span class="col-g more-span shou" @click.stop="goMore(item)">
          了解更多
          <img
            class="ml10"
            width="40"
            src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/jr.png"
            alt="上太科技"
          />
        </span>
      </div>
    </li>
    <!-- <li class="slide-li"></li> -->
  </ul>
</template>

<script>
export default {
  name: "",
  props: {
    navList: {
      type: Array,
      required: true,
    },
    active: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      advantageList: [
        {
          key: 1,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/pests/banner.png",
        },
        {
          key: 2,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/house/banner.png",
        },
        {
          key: 3,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/banner.png",
        },
        {
          key: 4,
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/water/banner.jpg",
        },
      ],
    };
  },
  created() {},
  methods: {
    goMore(data) {
      this.$router.push(data.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.programme-nav {
  position: relative;
  border-left: 1px solid rgba($color: #fff, $alpha: 0.05);
  margin-top: 30px;
  .data-li {
    height: 180px;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 40px;
    position: relative;
    margin-bottom: 10px;
    .more {
      display: none;

    }

    &:hover {
      .title-span {
        color: rgba($color: #fff, $alpha: 0.7);
      }

    }
    &.programme-active {
      &::before {
        height: 66%;
      }
      .title-span {
        color: rgba($color: #fff, $alpha: 1) !important;
      }

      .desc-p,
      .more-span {
        opacity: 1;
        display: inline-flex;
      }

      .more{
        display: block;
        .img {
          display: block;
        }
      }
      .desc-p {
        display: block;
      }
    }
      &::before {
      content: "";
      width: 1px;
      height: 0;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $color-active;
      transition: all 0.3s;
    }
    .title-span {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: 600;
      color: rgba($color: #fff, $alpha: 0.4);
      transition: all 0.3s;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .desc-p {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.8);
      line-height: 30px;
      opacity: 0;
      transition: all 0.3s;
      text-align: left;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .more-span {
      display: none;
      align-items: center;
      font-size: 18px;
      font-family: PingFang SC;

      color: #1dbf73;
      opacity: 0;
      transition: all 0.3s;
      img {
        width: 40px;
        object-fit: contain;
        transition: all 0.3s;
      }

    }
  }
}
@media screen and (min-width: 1600px) {
  .programme-nav {
    .data-li {
      height: 180px;
      &:hover {
        .title-span {
          color: rgba($color: #fff, $alpha: 0.7);
        }
      }
      .more {
        cursor: pointer;
      }
      .title-span {
        font-size: 40px;
      }
      .desc-p {
        font-size: 20px;
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .programme-nav {
    .data-li {
      height: 150px;
      &:hover {
        .title-span {
          color: rgba($color: #fff, $alpha: 0.7);
        }
      }
      .more {
        cursor: pointer;
      }
      .title-span {
        font-size: 30px;
      }
      .desc-p {
        font-size: 16px;
        margin: 10px 0;
        line-height: 24px;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .programme-nav {
    .data-li {
      height: 120px;
      &:hover {
        .title-span {
          color: rgba($color: #fff, $alpha: 0.7);
        }
      }
      .more {
        cursor: pointer;
      }
      .title-span {
        font-size: 24px;
      }
      .desc-p {
        font-size: 14px;
        margin: 8px 0;
        line-height: 20px;
      }
    }
  }
}
</style>
