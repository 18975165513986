<!--
 * @Author: your name
 * @Date: 2021-11-24 13:57:32
 * @LastEditTime: 2023-02-10 15:35:34
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 首页 产品中心
 * @FilePath: \new-website-1123\src\views\Home\product\index.vue
-->
<template>
  <div class="home-product pt80">
    <div class="product-box">
      <router-link :to="{ path: '/special/home' }">
        <div class="title-box">
          <h-title :titleimg="titleimg" :stitleimg="stitleimg"></h-title>
          <span class="cp-more">更多></span>
          <span class="pc-more">
            了解更多
            <img class="ml10" width="40" src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/jr.png" alt="上太科技" />
          </span>
        </div>
      </router-link>
      <div class="nav-box">
        <p-nav :nav-list="navList" :active="navActive" @change="navChange"></p-nav>
      </div>
      <div class="swiper-box">
        <swiper class="right-div swiper" ref="swiper" :options="swiperOptions">
          <swiper-slide v-for="item in navList" :key="item.key" :index="item.id">
            <div class="item-box" style="width: 100%" @click="goMore(item.url)" :style="{
              backgroundImage: 'url(' + item.cover + ') ',
              backgroundSize: '1000px 700px ',
            }">
              <div class="left-div">
                <span class="title-span">{{ item.model }}</span>
                <span class="title-span t-title">{{ item.title }}</span>
                <span class="desc-span">{{ item.desc }}</span>
                <span class="btn-span animation-3" @click.stop="goMore(item.url)">查看详情</span>
              </div>
            </div>
          </swiper-slide>
        </swiper>

        <div class="page page-prev shou" >
          <img src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/product/prev.png" alt="上太科技" />
        </div>
        <div class="page page-next shou" >
          <img src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/product/next.png" alt="上太科技" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hTitle from "../components/title.vue";
import pNav from "./nav.vue";
export default {
  name: "",
  components: {
    hTitle,
    pNav,
  },
  data() {
    let self = this;
    return {
      titleimg: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/whitetitle.png",
      stitleimg: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/whitetitles.png",
      navList: [
        {
          id: 1,
          label: "虫情测报仪",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg3.png",
          model: "ST-CQ101",
          title: "智能虫情测报仪",
          desc: "简约而不简单 是测报仪也是风景",
          url: "/special/CQ101",
        },
        {
          id: 2,
          label: "气象测报站",
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg6.png",
          model: "ST-X101",
          title: "光伏自动气象测报站",
          desc: "为您量身定制的气象站",
          url: "/special/x101",
        },
        {
          id: 3,
          label: "水肥机",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg8.png",
          model: "ST-SF101",
          title: "智能水肥机",
          desc: "智能操控 节水节肥",
          url: "/special/SF101",
        },
        {
          id: 4,
          label: "墒情仪",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg7.png",
          model: "ST-XJ101",
          title: "太阳能墒情仪",
          desc: "各类土质 全面检测",
          url: "/special/SQ101",
        },
        {
          id: 5,
          label: "孢子仪",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg1.png",
          model: "ST-BZ101",
          title: "智能孢子捕获仪",
          desc: "植物病害 检测的新利器",
          url: "/special/BZ101",
        },
        {
          id: 6,
          label: "捕虫器",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg11.png",
          model: "ST-LD/FX",
          title: "太阳能路灯捕虫器",
          desc: "照明与捕虫一机两用 照亮美丽环保新农村",
          url: "/special/stld",
        },
        {
          id: 7,
          label: "无人车",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg9.png",
          model: "ST-XJ101",
          title: "智能巡检无人车",
          desc: "多模式多场景，自由适配",
          url: "/special/XJ101",
        },
        {
          id: 8,
          label: "无人机",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg10.png",
          model: "",
          title: "多功能无人机",
          desc: "精密既高效无人既放心",
          url: "/special/MultifunctionalUAV",
        },
      ],
      navActive: 1,
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
          clickable: true,
        },
        navigation: {
          prevEl: '.page-prev',
          nextEl: '.page-next',
        },
        loop: true,
        speed: 800,
        mousewheel: false,
        slidesPerGroup: 1,
        on: {
          slideChange() {
            self.navActive = self.navList[this.realIndex].id;
          },
        },
      },
    };
  },
  created() { },
  methods: {
    navChange(val) {
      this.navActive = val;
      let arr = this.navList.map((item) => item.id);
      this.$refs.swiper.$swiper.slideTo(arr.indexOf(val) + 1);
    },
    goMore(data) {
      this.$router.push(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-product {
  width: 100%;
  height: 100vh;

  .pc-more {
    font-size: 24px;
    color: $color-active;
    position: absolute;
    right: -200px;
    top: 50%;

    img {
      transition: all 0.3s;
    }

    &:hover {
      img {
        transform: translateX(5px);
      }
    }
  }

  .product-box {
    width: 78%;
    margin: auto;
    position: relative;
    height: 100%;
    // overflow: hidden;
  }

  .title-box {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 2;

    .cp-more {
      display: none;
    }
  }

  .nav-box {
    position: absolute;
    top: 160px;
    left: 0;
    width: 58%;
    z-index: 2;
  }

  .swiper-box {
    position: relative;
    z-index: 1;
    // overflow: hidden;
    height: 100%;

    .page {
      position: absolute;
      top: calc(50% - 24px + 78px);

      &-prev {
        left: -60px;
      }

      &-next {
        right: -60px;
      }
    }
  }

  .pc-background-set {
    background: #1d1d1d;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    height: 68%;
  }

  .item-box {
    position: relative;
    height: 100%;
    background-position: right -130px bottom 80px;
    background-repeat: no-repeat;
    background-size: 600px;
    cursor: pointer;
    .left-div {
      position: absolute;
      bottom: 20%;
      left: 10%;
      display: flex;
      flex-direction: column;

      .title-span {
        font-size: 60px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #ffffff;

        &.t-title {
          margin-top: 20px;
        }
      }

      .desc-span {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.4;
        margin-top: 76px;
      }

      .btn-span {
        width: 160px;
        height: 60px;
        margin-top: 90px;
        background: $color-active;
        border-radius: 30px;
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
        line-height: 60px;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .data-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (min-height: 800px) {
  .home-product {
    .item-box {
      .left-div {
        bottom: 20%;
      }
    }
  }
}

@media screen and (max-height: 800px) {
  .home-product {
    .title-box {
      top: 10px;
    }

    .nav-box {
      top: 110px;
    }

    .item-box {
      .left-div {
        bottom: 10%;

        .title-span {
          font-size: 40px;

          &.t-title {
            margin-top: 10px;
          }
        }

        .desc-span {
          font-size: 24px;
          margin-top: 30px;
        }

        .btn-span {
          width: 120px;
          height: 40px;
          font-size: 18px;
          margin-top: 50px;
          line-height: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .home-product {
    .product-box {
      width: 100%;

      .title-box {
        width: 100%;

        .cp-more {
          display: block;
          position: absolute;
          color: $color-active;
          right: 0.73rem;
          font-size: 0.25rem;
          top: 0.79rem;
        }

        .home-title {
          font-size: 0.53rem;
          text-align: center;
          display: block;
          width: 100%;
          padding-top: 0.64rem;
        }
      }

      .nav-box {
        display: none;
      }
    }

    .pc-background-set {
      display: none;
    }

    .item-box {
      background-position: bottom center !important;
      background-size: 100% !important;
      background-color: #222222 !important;
      height: 70%;

      .left-div {
        bottom: auto;
        top: 0.6rem;
        left: 0;
        width: 100%;
        text-align: center;

        .title-span {
          font-size: 0.44rem;
        }

        .title-span.t-title {
          margin-top: 0.18rem;
          font-size: 0.44rem;
          font-weight: normal;
        }

        .btn-span {
          margin: 0 auto;
          margin-top: 0.22rem;
          width: 1.45rem;
          height: 0.55rem;
          line-height: 0.55rem;
          font-size: 0.22rem;
        }

        .desc-span {
          display: none;
        }
      }
    }
  }
}
</style>
