<!--
 * @Author: your name
 * @Date: 2021-11-24 16:25:30
 * @LastEditTime: 2023-02-10 15:32:07
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 首页 媒体 nav
 * @FilePath: \new-website-1123\src\views\Home\media\nav.vue
-->
<template>
  <ul class="media-nav">
    <li
      v-for="(item,index) in navList"
      :key="'media-' + index"
      :class="{
        'media-active': index == active,
      }"
      class="shou"
      @click="$emit('change', index)"
    >
      <span class="title-span">{{ item.name }}</span>
      <span class="time-span">{{ clearHtml(item.info) }}</span>
      <div class="more-div" @click="goNewsDetails">
          <span class="col-g">
            查看详情
            <img src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/jr.png" alt="" />
          </span>
      </div>
    </li>
  </ul>
</template>

<script>
import { clearHtml } from "@/utils/removeTag.js"
export default {
  name: "media-nav",
  props: {
    navList: {
      type: Array,
      required: true,
    },
    active: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    clearHtml(text){
      return clearHtml(text)
    },
    goNewsDetails(){
      this.$router.push({
        path: "/news/details",
        query: {
          id: this.navList[this.active].id,
        },
      });
    }

  },
};
</script>

<style lang="scss" scoped>
.media-nav {
  border-left: 1px solid rgba($color: #fff, $alpha: 0.05);
  li {
    display: flex;
    flex-direction: column;
    height: 180px;
    color: rgba($color: #fff, $alpha: 0.4);
    position: relative;
    padding-left: 30px;
    transition: all 0.3s;
    margin-bottom: 10px;
    &.media-active {
      &::before {
        height: 54%;
      }
      .title-span {
        font-family: PingFang SC;
        min-height: 30px !important;
        font-weight: 600;
        color: #ffffff;
      }
      .time-span {
        opacity: 1;
        color: #cccccc;
        font-size:20px;
        overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
      }
      .more-div {
        opacity: 1;
        font-size: 18px;
        img {
          transition: all 0.3s;
        }
        &:hover {
          img {
            transform: translateX(5px);
          }
        }
      }
    }



    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 0;
      background-color: $color-active;
      transition: all 0.3s;
    }
    .title-span {
      font-size: 30px;
      font-family: PingFang SC;
      min-height: 35px !important;
      font-weight: 600;
      transition: all 0.3s;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .time-span {
      opacity: 0;
      color: rgba($color: #fff, $alpha: 0.2);
      margin-top: 16px;
      transition: all 0.3s;
      font-size:20px;
      overflow: hidden;

      display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
    }
    .more-div {
      opacity: 0;
      // margin-top: 16px;

      & > span {
        display: inline-flex;
        align-items: center;
        img {
          width: 40px;
          margin-left: 10px;
          transition: all 0.3s;
        }
        &:hover {
          img {
            transform: translateX(5px);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .media-nav {
    li {
      height: 180px;
      .title-span {
        min-height: 30px !important;
        font-size: 30px;
      }
      .time-span {
        opacity: 0;
        margin-top: 16px;
        margin-bottom: 20px;
        font-size:20px;
        overflow: hidden;
        display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .media-nav {
    li {
      height: 150px;
      .title-span {
        min-height: 30px !important;
        font-size: 24px;
      }
      .time-span {
        opacity: 0;
        margin-top: 14px;
        margin-bottom: 18px;
        font-size:20px;

        overflow: hidden;
        display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .media-nav {
    li {

      .time-span {
        opacity: 0;
        margin-top: 14px;
        margin-bottom: 18px;
        font-size:20px;
        overflow: hidden;
        display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .media-nav {
    li {

      .time-span {
        opacity: 0;
        margin-top: 14px;
        margin-bottom: 18px;
        font-size:16px;
        overflow: hidden;
        display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
      }
    }
  }
}
@media screen and (max-height: 850px) {
  .media-nav {
    li {
      height: 120px;
      .title-span {
        min-height: 30px !important;
        font-size: 18px;
      }
      .time-span {
        opacity: 0;
        margin-top: 8px;
        margin-bottom: 10px;
        font-size:16px;
        display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
      }
    }
  }
}
</style>
