<!--
 * @Author: your name
 * @Date: 2021-11-24 09:25:26
 * @LastEditTime: 2022-01-26 16:29:30
 * @LastEditors: Please set LastEditors
 * @Description: 首页
 * @FilePath: \new-website-1123\src\views\Home\index.vue
-->
<template>
  <!-- <div class="home-page">
    <h-video></h-video>
    <h-about></h-about>
  </div> -->

  <div class="home-page">
    <swiper ref="mySwiper" :options="swiperOptions" class="swiper">
      <swiper-slide v-for="item in pageArr" :key="item">
        <component :is="item" :ref="'page' + item"></component>
      </swiper-slide>
      <!-- 分页器 -->
      <!-- <div class="swiper-pagination home-pagination" slot="pagination">
      </div> -->
      <div class="swiper-scrollbar"></div>
    </swiper>
  </div>
</template>

<script>
import hVideo from "./video.vue";
import hAbout from "./about";
import hProduct from "./product";
import hProgramme from "./programme";
// import hBusiness from "./business";
import hMedia from "./media";
import hParther from "./partner";
import stFooter from "@/components/stFooter.vue";
export default {
  name: "home-page",
  components: {
    hVideo,
    hAbout,
    hProduct,
    hProgramme,
    // hBusiness,
    hMedia,
    hParther,
    stFooter,
  },
  data() {
    return {
      pageArr: [
        "hVideo",
        "hAbout",
        "hProduct",
        "hProgramme",
        // "hBusiness",
        "hMedia",
        "hParther",
        // "stFooter",
      ],
      swiperOptions: {
        pagination: { // 分页器
          el: ".swiper-pagination",
          clickable: true,
        },
        speed: 800,
        direction: "vertical", // 垂直切换选项
        slidesPerView: 1,// 一页显示的个数
        // 当内容超出边框时自动添加滚动条
                scrollbar: {
            el: '.swiper-scrollbar',
            hide: false, // 隐藏滚动条
          },
        spaceBetween: 0, // 间距
        mousewheel: true,//
        slidesPerGroup: 1,
        simulateTouch: false,
        on: {
          slideChange() {
            let key = this.activeIndex;
            if(key == 4) {
               this.params.autoplay = false;
            }
          },
        },
      },
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.home-page {
  background: #161616;
  width: 100%;
  // height: 100%;
  height: 100vh;
  overflow: hidden;
}

::v-deep {
  .swiper {
    width: 100%;
    height: 100%;

  }

  .swiper-slide {
    // background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-container-scrollbar .swiper-slide {
    height: auto;
  }

  .swiper-container-free-mode > .swiper-wrapper {
    -webkit-transition-timing-function: linear;
    /*之前是ease-out*/
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    margin: 0 auto;
  }
  .swiper-scrollbar {
  position: absolute;
  z-index: 1;
}

.swiper-scrollbar-drag {
  background-color: #ccc;
  border-radius: 10px;
  height: 30px;
}
}
</style>
