<!--
 * @Author: your name
 * @Date: 2021-11-24 11:31:25
 * @LastEditTime: 2023-02-10 16:52:39
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 首页关于上太
 * @FilePath: \new-website-1123\src\views\Home\about\index.vue
-->
<template>
  <div class="home-about">
    <img class="back-img" src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/about-bg.png" alt="" />
    <div class="content-box pt80">
      <span class="title-span">关于上太</span>
      <p class="desc-p">
        四川上太科技有限公司是一家提供智慧农业服务的科技型企业。上太深耕科技创新，融合时代发展，赋能农业；以提质增效惠民惠农为核心，建立多个智慧农业生态布局；始终以科技为支撑，助力农业产业化转型升级。
      </p>

      <div class="col-g more-div">
        <span class="shou" @click="goAbout">
          了解更多
          <img class="ml10" src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/jr.png" alt="更多" />
        </span>
      </div>

      <div class="nav-box">
        <a-nav></a-nav>
      </div>
    </div>
  </div>
</template>

<script>
import aNav from "./nav.vue";
export default {
  name: "",
  components: {
    aNav,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    /**
     * 了解更多点击
     * @description:
     * @return {*}
     */
    goAbout() {
      this.$router.push({
        path: "/about/profile",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-about {
  position: relative;
  height: 100vh;
  .back-img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .content-box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-span {
      font-size: 60px;
      font-weight: bold;
      color: #ffffff;
    }
    .desc-p {
      margin-top: 25px;
      width: 920px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 30px;
    }
    .more-div {
      margin-top: 6px;
      & > span {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        font-size: 24px;
        margin-top: 32px;
        color: #1dbf73;
        img {
          transition: all 0.3s;
        }
        &:hover {
          img {
            transform: translateX(5px);
          }
        }
      }
    }
    .nav-box {
      position: absolute;
      bottom: 98px;
      width: 100%;
      padding: 0 12%;
    }
  }
}

@media only screen and (max-width: 800px) {
  .home-about {
    .back-img {
      height: 100%;
    }
    .content-box {
      .title-span {
        font-size: 0.44rem;

        margin-top: 0.73rem;
      }
      .desc-p {
        width: 86.4%;
        text-align: center;
        line-height: 0.35rem;
        margin-top: 0.33rem;
        font-weight: normal;
      }
      .more-div {
        margin-top: 0;
        line-height: 0.36rem;
        span {
          font-size: 0.25rem;
          margin-top: 0.18rem;
          img {
            width: 0.36rem;
          }
        }
      }

      .nav-box {
        bottom: 1.4rem;
        width: 86.4%;
        padding: 0;
        margin: 0 auto;
      }
    }
  }
}
</style>
